.navBar {
	width: 100%;
	position: fixed;
	top: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	background-color: var(--color1);
	/* color: var(--color4); */
}

.navBar > ul {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	flex-wrap: wrap;
}

.navBar li {
	margin: 0.5em;
	font-size: clamp(16px, 1vw, 20px);
}

/*  */

#navHeading {
	margin: 0.5em;
	font-size: clamp(24px, 1.5vw, 28px);
	font-family: "Raleway", sans-serif;
}

@media (max-width: 650px) {
	.navBar {
		position: relative;
		justify-content: center;
	}

	.navBar > ul {
		width: 100%;
	}
}
