.whyWhatschartBox {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-align: left;
}

.whyWhatschartBox > div {
	width: clamp(80px, 7vw, 100px);
	height: clamp(80px, 7vw, 100px);
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 0.3em;
	font-size: clamp(80px, 7vw, 100px);
}

.whyWhatschartBox > section {
	width: 100%;
	max-width: 700px;
	height: clamp(80px, 6vw, 100px);
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

/*  */

#whyWhatschart {
	background-color: var(--color3);
}

#whyWhatschartList {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 1em;
}

#whyWhatschartList > li {
	margin-bottom: 4em;
}

@media (max-width: 650px) {
	.whyWhatschartBox {
		flex-direction: column;
		text-align: center;
	}

	.whyWhatschartBox > div {
		margin-right: 0;
		margin-bottom: 0.5em;
	}

	#whyWhatschartList {
		margin: 0 0.5em;
	}
}
