.fileSelectorWrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.fileFormOptions {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.rmStopwordsWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 1em;
}

.rmStopwordsWrapper > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 1em;
}

.colorOptionsWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.colorOptionsWrapper > div {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-bottom: 0.5em;
}

.colorOptionsWrapper label {
	width: 150px;
	display: inline-block;
	margin-left: 1em;
	text-align: left;
}

/*  */

#fileForm {
	background-color: var(--color3);
}

#fileForm label {
	font-weight: 500;
}

#fileForm label:not(.colorOptionsWrapper label) {
	margin-bottom: 0.5em;
}

#fileForm input[type="checkbox"] {
	transform: scale(1.3);
}

#fileForm input[type="color"] {
	width: 6em;
	height: 3em;
}

#fileForm > div,
#fileForm > section {
	margin-bottom: 1em;
}

#buttonGenerate {
	width: 200px;
	margin-top: 2em;
	border: solid 1px var(--color4);
}
