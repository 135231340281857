/* classes */

.chartSection {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin-top: 2em;
}

.sectionHeading {
	margin-bottom: 1em;
	font-size: clamp(14px, 4vw, 24px);
}

.sectionHeadingII {
	/* Use this class when there are multiple headings for a section */
	margin-top: 2em;
	margin-bottom: 1em;
	font-size: clamp(14px, 4vw, 24px);
}

.sectionDescription {
	margin-top: 1em;
	font-size: clamp(12px, 3vw, 18px);
}

.iconWrapper {
	width: 80px;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 80px;
	color: var(--iconColor);
}

.summaryBox {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.summaryBox {
	margin: 1.5em;
}

.summaryBox p:is(:first-of-type) {
	margin-top: 1em;
	margin-bottom: 0.2em;
	font-weight: 500;
}

/* wrappers to make chartjs charts responsive */

.dividedHorizontalBarWrapper {
	position: relative;
	width: 40vw;
	max-width: 300px;
	height: 40vh;
	max-height: 300px;
}

.verticalBarWrapper {
	position: relative;
	width: 80vw;
	max-width: 600px;
	height: 40vh;
	max-height: 300px;
}

.chartsHorizontal {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}

.chartsHorizontal > div:is(:first-of-type) {
	margin-right: 2em;
}

/* Individual sections formatting */

#madeWithTag {
	position: absolute;
	top: 0.5em;
	right: 0.5em;
	align-self: flex-end;
	font-weight: 700;
	font-size: clamp(9px, 0.7vw, 16px);
	font-style: italic;
}

#chart {
	width: 100%;
	max-width: 900px;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	align-self: center;
	padding: 3em;
	background-color: var(--backgroundColor);
	color: var(--textColor);
	line-height: 1.3;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
}

#chart h1,
h2 {
	font-family: "Raleway", sans-serif;
}

#chart h1 {
	font-weight: 700;
	font-size: clamp(21px, 6vw, 56px);
}

#chart header {
	margin-bottom: 1em 0;
}

#summaries {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	flex-wrap: wrap;
}

#sectionEmojis > .sectionDescription,
#sectionTiming > .sectionDescription {
	margin-bottom: 1em;
}

/* Media queries */

@media (max-width: 650px) {
	#chart {
		padding: 2em;
	}

	#chartInDepthSummary {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-right: 0;
	}

	.chartsHorizontal > div:is(:first-of-type) {
		margin-right: 0em;
	}
}

@media (max-width: 480px) {
	#madeWithTag {
		top: 0.2em;
		right: 0.2em;
	}
	#chart {
		padding: 1.5em;
	}

	.chartSection {
		margin-top: 1em;
	}

	.iconWrapper {
		width: 60px;
		height: 60px;
		font-size: 60px;
	}

	.summaryBox {
		margin: 0.7em;
	}

	.summaryBox p:is(:first-of-type) {
		margin-top: 1em;
		margin-bottom: 0.2em;
	}

	.summaryBox p {
		font-size: 12px;
	}

	.sectionHeadingII {
		/* Use this class when there are multiple headings for a section */
		margin-bottom: 0.5em;
	}

	.sectionDescription {
		margin-top: 0.5em;
	}
}
