#howtoPage {
	text-align: left;
}

#howtoPage h3 {
	margin: 0.5em 0;
}

#howtoPage > ol > li {
	font-size: clamp(18.72px, 1.2vw, 22.72px);
	margin-bottom: 1.5em;
}

#howtoPage div {
	font-size: clamp(16px, 1vw, 20px);
}

.howtoSubListWrapper {
	margin-left: 2em;
}

.howtoSubListWrapper > li {
	margin: 0.5em 0;
}

.paraWrapper {
	width: 100%;
	max-width: 1000px;
	padding: 0 1em;
}
